import {HttpException} from "../exception";
import {LeafSensorDto} from "dto/LeafSensorDto";
import {LeafSensorRegistrationDto} from "dto/LeafSensorRegistrationDto";
import {requestOld} from "../utils/Utils";
import {PlantDto} from "dto/PlantDto";
import {LeafSensorWithPlantDto} from "dto/LeafSensorWithPlantDto";


const ENDPOINT = "/api/v2/leaf-sensor"

class LeafSensorService {
    async getLeafSensorsByCustomerId(customerId: string, addIndirect : boolean = false, addPlants: boolean = false, hidden : boolean | undefined = undefined): Promise<LeafSensorDto[] | LeafSensorWithPlantDto[]> {
        const jwt = localStorage.jwt;
        const searchParams = new URLSearchParams();
        searchParams.append('customer', customerId);
        if (addIndirect) {
            searchParams.append('indirect','true');
        }
        if (addPlants) {
            searchParams.append('add-plant', 'true');
        }
        if (hidden !== undefined) {
            searchParams.append('hidden', hidden + '');
        }
        const url = `${ENDPOINT}/?${searchParams}`;
        const response = await fetch(url.toString(), {
            headers: {
                "Authorization": `Bearer ${jwt}`
            },
            method: "get",

        });
        if (!response.ok) {
            throw new HttpException(response);
        }
        const result = await response.json();
        return result as LeafSensorDto[];

    }
    async getMyLeafSensors(addIndirect : boolean = false, addPlants: boolean = false): Promise<LeafSensorDto[] | LeafSensorWithPlantDto[]> {
        const jwt = localStorage.jwt;
        const searchParams = new URLSearchParams();
        if (addIndirect) {
            searchParams.append('indirect','true');
        }
        if (addPlants) {
            searchParams.append('add-plant', 'true');
        }
        console.log(searchParams.toString());
        const url = `${ENDPOINT}/mine?${searchParams}`;
        console.log("URL", url);
        const response = await fetch(url.toString(), {
            headers: {
                "Authorization": `Bearer ${jwt}`
            },
            method: "get",

        });
        if (!response.ok) {
            throw new HttpException(response);
        }
        const result = await response.json();
        return result as LeafSensorDto[];
    };

    async getAllLeafSensors(): Promise<LeafSensorDto[]> {
        const jwt = localStorage.jwt;
        const response = await fetch(`${ENDPOINT}`, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            },
            method: "get"
        });
        if (!response.ok) {
            throw new HttpException(response);
        }
        const result = await response.json();
        return result as LeafSensorDto[];

    }

    async setNotificationFlag(leafSensorId: string, notificationEnabled: boolean) {
        const jwt = localStorage.jwt;
        const response = await fetch(`${ENDPOINT}/${leafSensorId}/notification`, {
            headers: {
                "Authorization": `Bearer ${jwt}`,
                "Content-Type": "application/json"
            },
            method: "post",
            body: JSON.stringify({ notificationEnabled } )
        });
        if (!response.ok) {
            throw new HttpException(response);
        }
    }

    async getPlantsByLeafSensorId(leafSensorId: string): Promise<PlantDto[]> {
        const response = await requestOld(`${ENDPOINT}/${leafSensorId}/plants`);
        if (!response.ok) {
            throw new HttpException(response);
        }
        const result = await response.json();
        return result as PlantDto[];
    }

    async register(leafSensor: LeafSensorRegistrationDto): Promise<LeafSensorDto> {
        const response = await requestOld(`${ENDPOINT}`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "post",
            body: JSON.stringify(leafSensor)
        });
        if (!response.ok) {
            throw new HttpException(response);
        }
        return await response.json();
    }
}

const instance = new LeafSensorService();


export {instance as LeafSensorService};
