import {ALL_RANGE_WIDTHS, RangeWidth} from "../../dbo/RangeWidth";
import {AppState} from "../../state";
import React, {MutableRefObject, useEffect, useState} from 'react';
import {SideMenu} from "./SideMenu";
import DashboardPageState, {
    DASHBOARD_PAGE_EMPTY_STATE,
    DeviceAndField,
    loadMissingData,
    toggleEntry
} from "../../state/DashboardPageState";
import './DashboardPage.css';
import SideMenuEntry from "../../dbo/SideMenuEntry";
import {Chart} from "../Chart";
import {DeviceChartData} from "../../dbo/ChartDataElement";
import {DateService} from "../../service/DateService";
import {indexBy} from "../../utils/Utils";
import {DeviceService} from "../../service/DeviceService";
import {LoginService} from "../../service/LoginService";
import {ProfileService} from "../../service/ProfileService";
import {DeviceDto} from "../../dto/DeviceDto";
import {CustomerDto} from "../../dto/CustomerDto";
import {ProfileDto} from "../../dto/ProfileDto";
import {PlantDto} from "../../dto/PlantDto";

interface DashboardPageProps {
    state: AppState,
    onRangeSelected: (rangeWidth: RangeWidth) => void,
    onCustomerSelected: (customer: CustomerDto) => void;
    dataStartRef: MutableRefObject<null>;
    customerId: string;
}

interface MaxAndMin {
    readonly xMin: number | undefined;
    readonly xMax: number | undefined;

}

function findRange(dashboardPageState: DashboardPageState, rangeWidth: RangeWidth): MaxAndMin {
    let xMin: number | undefined;
    let xMax: number | undefined;
    xMax = DateService.getEndOfDay(dashboardPageState.selectedDate).getTime() / 1000;
    xMin = DateService.getEndOfDay(DateService.getStartDate(dashboardPageState.selectedDate, rangeWidth)).getTime() / 1000;
    return {xMin, xMax};
}

function getDeviceById(pageState: DashboardPageState, deviceId: string): DeviceDto | null {
    for (const device of pageState.devices) {
        if (device.id === deviceId) {
            return device;
        }
    }
    return null;
}

async function loadProfiles(): Promise<{ [id: string]: ProfileDto }> {
    const customerId = LoginService.getLoginInformation().customerId;
    const profiles = await ProfileService.getProfilesByParentOfCustomerId(customerId);
    const profilesById = indexBy(profiles, 'id');
    return profilesById;
}

export default function DashboardPage(props: DashboardPageProps) {
    const {state} = props;
    const [pageState, setState] = useState(DASHBOARD_PAGE_EMPTY_STATE);

    useEffect(() => {
        loadProfiles().then(profilesById => {
            setState(pageState => {
                return {...pageState, profilesById}
            });
        });
    }, []);
    useEffect(() => {
        DeviceService.getByCustomerId(props.customerId, true, false)
            .then(devices => {
                console.info("Device list loaded");
                setState(pageState => {
                    return {...pageState, devices, loadingStatus: "resolved"}
                });
            }).catch(error => {
            console.error("Unable to load device list");
            setState(pageState => {
                return {...pageState, loadingStatus: "rejected"}
            });
        })
    }, [props.customerId]);

    useEffect(function () {
        const plants: PlantDto[] = [];
        for (const device of pageState.devices) {
            plants.push(device.plant as PlantDto);
            const plantsById: { [id: string]: PlantDto } = {};
            for (const plant of plants) {
                const id = plant.id as string;
                plantsById[id] = plant;
            }
            setState(pageState => {
                return {...pageState, plants, plantsById};
            })
        }
    }, [pageState.devices]);


    function renderItem(item: string, deviceId: string) {
        const fieldName = item.split("@")[2];
        if (!fieldName) {
            return;
        }
        const device = getDeviceById(pageState, deviceId);
        if (!device) {
            return;
        }
        const profile = pageState.profilesById[pageState.plantsById[device.plantId].deviceProfileId];
        if (!profile) {
            return;
        }
        const sensors = indexBy(profile.sensors, 'id');
        const chartData = pageState.deviceData[deviceId];
        const title = device.description + ' - ' + sensors[fieldName].description;
        const sensor = sensors[fieldName];
        let fieldChartData = chartData[fieldName];
        // if (sensor.chartFormula && fieldChartData) {
        //     fieldChartData = sensor.chartFormula(fieldChartData);
        // }
        if (sensor === undefined) {
            console.error(`Missing field data for field ${fieldName}`, sensors);
        } else if (fieldChartData === undefined) {
            console.error(`Missing chart data for field ${fieldName}`, pageState.deviceData);
        } else {

            switch (sensor.chartType) {
                case "bars":
                    console.log("FIXME: Unimplemented");
                    break;
                case "line":
                default:
                    return <div key={item} className="charts">
                        <Chart title={title} data={fieldChartData}
                               syncId={'chartSyncId'}
                               xMin={xMin}
                               xMax={xMax}
                               yMin={sensor.yMin}
                               yMax={sensor.yMax}
                               hasBaseline={true}
                        />
                    </div>;
            }
        }
    }

    const {xMin, xMax} = findRange(pageState, state.rangeWidth);

    async function onEntryToggled(entry: SideMenuEntry) {
        console.log("Entry toggled:", entry);
        const daf: DeviceAndField = {
            device: entry.device,
            field: entry.field
        };

        const newState = await toggleEntry(pageState, state.rangeWidth, daf);
        setState(newState);
    }

    async function onRangeSelected(range: RangeWidth) {
        console.log("Selecting range", range);
        const newDeviceData: { [id: string]: DeviceChartData } = {};
        let newState = {...pageState, deviceData: newDeviceData};
        newState = await loadMissingData(newState, pageState.selectedItems, range);
        setState(newState);
        props.onRangeSelected(range);
    }

    async function onDateSelected(date: Date) {
        console.log("Selecting date", date);
        const newDeviceData: { [id: string]: DeviceChartData } = {};
        let newState = {...pageState, selectedDate: date, deviceData: newDeviceData};
        newState = await loadMissingData(newState, pageState.selectedItems, state.rangeWidth);
        setState(newState);
    }

    if (pageState.loadingStatus === 'pending') {
        console.log(pageState);
    }
    return (
        <div className="dashboard-container">
            <div className="dashboard-menu">
                <SideMenu
                    onCustomerSelected={props.onCustomerSelected}
                    customers={[]}
                    selectedCustomerId={''}
                    profilesById={pageState.profilesById}
                    devices={pageState.devices}
                    plants={pageState.plants}
                    onDateSelected={onDateSelected}
                    ranges={ALL_RANGE_WIDTHS} onRangeSelected={onRangeSelected}
                    selectedRangeId={state.rangeWidth.id}
                    selectedItems={pageState.selectedItems}
                    selectedDate={pageState.selectedDate}
                    loadingStatus={pageState.loadingStatus}
                    onEntryToggled={onEntryToggled}/>
            </div>
            <div className="dashboard-body">
                {Object.entries(pageState.selectedItems).map(([item, deviceId]) => renderItem(item, deviceId))}
            </div>
        </div>
    );
}
