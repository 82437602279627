import {HttpException} from "../exception";
import {DeviceDto} from "dto/DeviceDto";


const ENDPOINT = "/api/v2/device"

class DeviceService {
    async getByCustomerId(customerId: string, addPlant: boolean, hidden: boolean | undefined): Promise<DeviceDto[]> {
        const jwt = localStorage.jwt;
        const params = new URLSearchParams();
        params.append("customer", customerId);
        if (addPlant) {
            params.append("add-plant", '');
        }
        if (hidden !== undefined) {
            params.append('hidden', hidden + '');
        }

        const response = await fetch(`${ENDPOINT}?${params}`, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            },
            method: "get"
        });
        if (!response.ok) {
            throw new HttpException(response);
        }
        const result = await response.json();
        return result as DeviceDto[];

    }

}

const instance = new DeviceService();


export {instance as DeviceService};
