import React, {useRef, useState} from 'react';
import './App.css';
import {initialState} from "./state";
import {LoginService} from "./service/LoginService";
import {Login} from "./components/Login";
import {RangeWidth} from "./dbo/RangeWidth";
import UserPage from "./components/user/UserPage";
import DashboardPage from "./components/dashboard/DashboardPage";
import {Navigation, NavigationItem} from "./components/Navigation";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Logout} from "./components/logout/Logout";
import {AdminPage} from "./components/admin/AdminPage";
import {BackofficePage} from "./components/backoffice/BackofficePage";
import {CustomerDto} from "dto/CustomerDto";
import {IfRole} from "./components/IfRole";
import {HttpException} from "./exception";
import {useAsync} from "./hooks/useAsync";
import {getCustomersByAncestorId, treefyCustomers} from "./service/CustomerService";
import {TreeCustomer} from "./dbo/TreeCustomer";
import {AnalysisPage} from "./components/analysis/AnalysisPage";



function getCustomerSelector(customers: CustomerDto[], selectedCustomerId: string | undefined, onCustomerChanged: (customerId: string) => void) {
    const selectedCustomer = customers.find(c => c.id === selectedCustomerId);
    if (customers.length === 1) {
        return <li key='customer-selector'>{selectedCustomer?.description}</li>
    } else {
        const tree = treefyCustomers(customers);
        return <select key='customer-selector' value={selectedCustomerId}
                       onChange={event => onCustomerChanged(event.target.value)}>
            {tree.map(c => <option key={c.id} value={c.id}>{c.treeLabel}</option>)}
        </select>
    }
}

function makeTopNavigation(customers: CustomerDto[], selectedCustomerId: string | undefined, onCustomerChanged: (customerId: string) => void): NavigationItem[] {
    return [{
        type: 'entry',
        requiredRole: "user",
        description: "Map View",
        destination: "/"
    }, {
        type: 'entry',
        requiredRole: "user",
        description: "Dashboard",
        destination: "/dashboard"
    }, {
        type: 'entry',
        requiredRole: "admin",
        description: "Admin",
        destination: "/admin"
    }, {
        type: 'entry',
        requiredRole: "backoffice",
        description: "Backoffice",
        destination: "/backoffice"
    }, {
        type: 'entry',
        requiredRole: "analyst",
        description: "Data Analysis",
        destination: "/analysis"
    }, {
        type: 'spacer',
        id: '1'
    }, {
        type: 'element',
        element: getCustomerSelector(customers, selectedCustomerId, onCustomerChanged)
    }, {
        type: 'entry',
        requiredRole: undefined,
        description: "Logout",
        destination: "/logout"
    }];

}


const App = () => {
    const [state, setState] = useState(initialState());
    const loginInformation = LoginService.getLoginInformation();
    const customersAsync = useAsync({
        promiseFn: () => getCustomersByAncestorId(state.selectedCustomerId),
        watch: loginInformation?.username,
        waitChange: true
    });

    async function login(username: string, password: string) {
        try {
            const loginInformation = await LoginService.login(username, password, {});
            console.log("Login successful");

            setState({...state, login: loginInformation, selectedCustomerId: loginInformation.customerId, error: null});
        } catch (ex) {
            const e = ex as HttpException;
            console.log(e);
            let message = '';
            if (e.code === 401) {
                message = 'Invalid credentials';
            } else if (e.code >= 500 && e.code < 600) {
                message = "Internal server error";
            } else {
                message = `Unrecognized error (code ${e.code})`;
            }
            const error = {
                message
            }
            setState({...state, login: null, error});
        }
    }

    async function logout() {
        setState({...state, login: null, selectedCustomerId: undefined});
    }

    const dataStartRef = useRef(null);

    function changeRangeWidth(rangeWidth: RangeWidth) {
        console.log("Changing range to ", rangeWidth);
        setState({...state, rangeWidth});
    }

    function changeCustomer(customer: CustomerDto) {
        console.info("Changing customer to", customer);
        setState(state => ({...state, selectedCustomerId: customer.id}));
    }

    function onCustomerChanged(customerId: string) {
        console.info("Changing customer to", customerId);
        setState(state => ({...state, selectedCustomerId: customerId}));
    }

    if (!state.login) {
        return <Login onSubmit={login} error={state.error?.message}/>
    } else {

        const customers = customersAsync.status === 'fulfilled' ? customersAsync.data : [];
        const navigationItems = makeTopNavigation(customers, state.selectedCustomerId as string, onCustomerChanged);
        return <div className="app">
            <Router>
                <Navigation items={navigationItems} loginInformation={state.login}/>
                <Routes>
                    <Route path="/" element={
                        <IfRole role={'user'}>
                            <UserPage onRangeSelected={changeRangeWidth}
                                      selectedCustomerId={state.selectedCustomerId as string}
                                      dataStartRef={dataStartRef} state={state}/>
                        </IfRole>
                    }>
                    </Route>
                    <Route path="/dashboard" element={
                        <IfRole role={'user'}>
                            <DashboardPage onRangeSelected={changeRangeWidth}
                                           onCustomerSelected={changeCustomer}
                                           customerId={state.selectedCustomerId as string}
                                           dataStartRef={dataStartRef} state={state}/>
                        </IfRole>
                    }>
                    </Route>

                    <Route path="/admin" element={
                        <IfRole role={'admin'}>
                            <AdminPage onRangeSelected={changeRangeWidth}
                                       customerId={state.selectedCustomerId as string}
                                       state={state}
                            />
                        </IfRole>
                    }>
                    </Route>
                    <Route path="/backoffice/*" element={
                        <IfRole role={'backoffice'}>
                            <BackofficePage
                                customerId={state.selectedCustomerId as string}
                            />
                        </IfRole>
                    }>
                    </Route>
                    <Route path="/analysis" element={
                        <IfRole role={'analyst'}>
                            <AnalysisPage
                            />
                        </IfRole>
                    }>
                    </Route>
                    <Route path="/logout" element={
                        <Logout onLogout={logout}/>
                    }>
                    </Route>
                </Routes>
            </Router>
        </div>
    }
};


export default App;
