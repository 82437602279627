import {LeafSensorService} from "../../service/LeafSensorService";
import {Dict} from "../../utils/Dict";
import {LoadingStatus} from "../../dbo/LoadingStatus";
import {PlantDto} from "../../dto/PlantDto";
import {LeafSensorDto} from "../../dto/LeafSensorDto";
import {LeafSensorWithPlantDto} from "../../dto/LeafSensorWithPlantDto";

export interface AdminPageState {
    readonly leafSensors: LeafSensorDto[];
    readonly plantByLeafSensorId: Dict<PlantDto>;
    readonly loadingStatus: LoadingStatus;
    readonly showHidden: boolean;
    readonly showDisconnected: boolean;
}

export const ADMIN_PAGE_EMPTY_STATE: AdminPageState = {
    leafSensors: [],
    plantByLeafSensorId: {},
    loadingStatus:"pending",
    showHidden: false,
    showDisconnected: true
};

export async function getInitialAdminPageState(customerId: string): Promise<AdminPageState> {
    const leafSensors = await LeafSensorService.getLeafSensorsByCustomerId(customerId, false, true) as LeafSensorWithPlantDto[];
    const plantByLeafSensorId: Dict<PlantDto> = {};

    console.log("LEAF", leafSensors);
    for (const leafSensor of leafSensors) {
        //console.log("PLANT", leafSensor.plant);
        plantByLeafSensorId[leafSensor.id] = leafSensor.plant;
    }
    return {
        leafSensors, plantByLeafSensorId, loadingStatus: "resolved", showHidden: false, showDisconnected: true
    };
}
