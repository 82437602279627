import React, {useState} from 'react';
import './TreeView.css';

export interface TreeNode<T> {
    id: string,
    label: JSX.Element,
    selected: boolean,
    payload: T,
    children: TreeNode<T>[]
}

interface NodeState {
    expanded: boolean,
}

interface TreeViewProps<T> {
    node: TreeNode<T>,
    onClick: (node: TreeNode<T>) => void,
}

export function TreeView<T>(props: TreeViewProps<T>) {
    const [state, setState] = useState({expanded: false});

    function toggleExpanded() {
        setState({...state, expanded: !state.expanded});
    }

    function renderNodeExpander(node: TreeNode<T>, state: NodeState) {
        if (node.children.length === 0) {
            return <span className="clickable tree-expander">&nbsp;</span>;
        } else if (state.expanded) {
            return <span className="clickable tree-expander" onClick={toggleExpanded}>⊟</span>
        } else {
            return <span className="clickable tree-expander" onClick={toggleExpanded}>⊞</span>
        }
    }

    function renderNodeHeader(node: TreeNode<T>) {
        return <div className="tree-header">
            {renderNodeExpander(node, state)}
            <label className="clickable"><input type="checkbox"
                                                onChange={() => props.onClick(node)}
                                                checked={node.selected}/>{node.label} </label></div>;
    }

    function renderChildren(node: TreeNode<T>) {
        if (!state.expanded) {
            return null;
        } else {
            return <ol>
                {props.node.children.map(child => <li key={child.id} className="tree-node"><TreeView node={child}
                                                                                      onClick={props.onClick}/></li>)}
            </ol>;
        }
    }


    return <div className="tree-view">
        {renderNodeHeader(props.node)}
        {renderChildren(props.node)}
    </div>

}
